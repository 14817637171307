<template>
  <v-main id="core-view" class="grey lighten-3" style="position: relative">
    <v-fade-transition mode="out-in" style="position: relative">
      <router-view style="position: relative"/>
    </v-fade-transition>
  </v-main>
</template>

<script>
export default {
  name: 'CoreView',
  components: {},

  metaInfo () {
    return {
      title: 'AutoPass Swagger Directory'
    }
  }
}
</script>

<style>

</style>
